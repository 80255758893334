import MuiCircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {
  cancerColor,
  neutral60,
  primary,
  primaryContainer,
  primaryOnContainer,
  secondary,
  secondary95,
  tertiary,
} from '../constants/colors';
import { noHover } from '../constants/styles';
import AddCircleIcon from '../icons/AddCircleOutlined';
import { apiIsUserPhysician } from '../utils/api';
import { isMissingResult, isReferralReminderDue } from '../utils/chartUpdatesUtils';
import { formatDateLong, formatDateLongUiText } from '../utils/date';
import { getCancerScreening } from '../utils/patientUtils';
import Recommended from '../utils/recommended';
import { hasReferralBeenRequested, hasReferralBeenSent } from '../utils/referral';
import { printRequisition, requisitionAvailable } from '../utils/requisition';
import {
  chartFamilyHistoryToShow,
  chartHistoryTypeName,
  chartPersonalHistoryToShow,
  chartSocialHistoryToShow,
  getLatestTest,
  getReviewedTests,
  hasChartHistoryNotReviewed,
  hasLatestTestNotReviewed,
  inConsultWindow,
  isChartHistoryItemUserEditable,
  isLatestTest,
  isNewPatient,
  isNextTestQuestionnaire,
  isPreNewPatient,
  isReferral,
  isScreeningConsult,
  isScreeningExternalMrp,
  isScreeningPreConsult,
  screeningRiskStatus,
  testTypeName,
  testTypeReferPatient,
} from '../utils/screenings';
import { capitalize } from '../utils/string';
import { testKey } from '../utils/tests';
import Button from './Button';
import Checkbox from './Checkbox';
import DelayedFade from './DelayedFade';
import Dialog from './Dialog';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import ExpansionPanel from './ExpansionPanel';
import LabelBadge from './LabelBadge';
import NewResultChart from './NewResultChart';
import NextTestDue from './NextTestDue';
import TestValue from './TestValue';
import Tooltip from './Tooltip';
import { chartTitle, dialogActions } from './styles';

const styles = (theme) => ({
  action: {
    position: 'absolute',
    right: 16,
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2.5),
    paddingRight: 30,
  },
  actionsAddTest: {
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(0.5),
  },
  addCircleButton: {
    color: primary,
    marginLeft: theme.spacing(1),
  },
  addCircleIcon: {
    height: 18,
    width: 18,
  },
  badge: {
    marginLeft: theme.spacing(3),
  },
  badgeSecondary: {
    marginLeft: theme.spacing(1),
  },
  badgeTitle: {
    marginLeft: theme.spacing(3),
  },
  buttonAddTest: {
    paddingRight: 23,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  chartTitle: chartTitle(theme),
  closeButton: {
    backgroundColor: primaryContainer,
    color: primaryOnContainer,
    padding: theme.spacing(0.5),
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(2.5),
  },
  dash: {
    ...theme.typography.body1,
    marginRight: theme.spacing(1),
  },
  dialogActions: {
    ...dialogActions(theme),
  },
  dialogCheckbox: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  emptyText: {
    color: neutral60,
  },
  externalMrp: {
    fontStyle: 'italic',
    color: secondary,
    marginTop: theme.spacing(1.5),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  gutter: {
    paddingLeft: 61,
  },
  iconAdd: {
    height: 18,
    marginRight: theme.spacing(1),
    width: 18,
  },
  historyItem: {
    backgroundColor: secondary95,
    paddingRight: 6,
    paddingLeft: 4,
    marginLeft: -4,
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  itemAction: {
    '&:hover > $action': {
      opacity: 1,
    },
  },
  itemBottom: {
    marginBottom: 20,
  },
  itemElement: {
    minWidth: 132,
  },
  itemElementWide: {
    minWidth: 164,
  },
  itemForm: {
    alignItems: 'baseline',
  },
  listItem: {
    marginBottom: theme.spacing(0.5),
  },
  missing: {
    color: tertiary,
  },
  noHover,
  notice: {
    fontStyle: 'italic',
    color: secondary,
    marginTop: theme.spacing(0.5),
  },
  overdue: {
    color: tertiary,
  },
  section: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  sectionTestSchedule: {
    position: 'relative',
  },
  spacing: {
    marginLeft: theme.spacing(2),
  },
  summary: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summaryActions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  summaryItem: {
    width: 168,
    alignItems: 'center',
  },
  summaryType: {
    width: 294,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
});

function ScreeningExpansionPanel(props) {
  const {
    classes,
    className,
    expanded,
    handleAcceptClick: handleAcceptClickProp,
    handleAddChartHistoryClick,
    handleAddTestClick,
    handleChartUpdateCompleteClick: handleChartUpdateCompleteClickProp,
    handleDeleteTestClick,
    handleEditChartHistoryClick,
    handleEditTestClick,
    handleExpandClick,
    handlePrintRequisitionClick: handlePrintRequisitionClickProp,
    handleReferralClick,
    handleRemoveChartHistoryClick,
    handleRequistionReleasedComplete,
    onTransitionEnd,
    otherScreening,
    patient,
    screening,
  } = props;

  const { id: patientId } = patient;

  const [recommended, setRecommended] = React.useState(new Recommended(screening, patient));
  const [option, setOption] = React.useState(null);
  const [fastForward, setFastForward] = React.useState(false);
  const [editingTestSchedule, setEditingTestSchedule] = React.useState(false);
  const [requisitionLoading, setRequisitionLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);

  React.useEffect(() => {
    const newRecommended = new Recommended(screening, patient);
    setRecommended(newRecommended);
  }, [patient, screening]);

  React.useEffect(() => {
    // Patient changes without unmounting when using patient chart search
    setEditingTestSchedule(false);
  }, [patientId]);

  const handleAcceptClick = () => {
    // Check that actual next test type and due were changed
    if (editingTestSchedule && recommended.isEqualToScreening(screening)) {
      setEditingTestSchedule(false);
      return Promise.resolve();
    }
    return handleAcceptClickProp(recommended, screening).then(() => {
      if (editingTestSchedule) {
        setEditingTestSchedule(false);
      }
    });
  };

  const handleCancelEditingClick = () => {
    setEditingTestSchedule(false);
    // Set back to original recommendation, so if user edits
    // test schedule again without refreshing page, prior
    // cancelled recommendation is cleared
    recommended.setPhysicianRecommendation(null);
  };

  const handleClose = () => {
    setOption(null);
  };

  const handleEditTestScheduleClick = () => {
    setEditingTestSchedule(true);
  };

  function handleChartUpdateCompleteClick(optionProp) {
    // Detect and present to user the option to fast forward new patient screening
    // when referral to specialist or for colonoscopy or CT colonoscopy has already
    // occured outside of the chek system

    if (isPreNewPatient(screening) && !isScreeningExternalMrp(screening)) {
      if (recommended.referral()) {
        setOption('fast forward referral');
        return Promise.resolve();
      }
      if (recommended.referPatient()) {
        if (recommended.nextTestType() === 'colonoscopy') {
          setOption('fast forward screening');
          return Promise.resolve();
        }
      }
    }

    return handleChartUpdateCompleteClickProp(screening, optionProp);
  }

  // Continuing after option to fast foward new patient screening presented to user
  const handleContinueClick = () =>
    handleChartUpdateCompleteClickProp(screening, fastForward ? option : null).then(handleClose);

  const handleOkClick = () => handleChartUpdateCompleteClick(option).then(handleClose);

  const handlePrintRequisitionConfirm = async (primaryScreening, secondaryScreening) => {
    handleClose();
    setRequisitionLoading(true);
    const secondaryScreeningNextTestType = secondaryScreening?.nextTest?.type ?? null;
    const success = await printRequisition(patientId, primaryScreening.type, secondaryScreeningNextTestType);
    setRequisitionLoading(false);
    if (success) {
      handlePrintRequisitionClickProp(primaryScreening);
      if (secondaryScreening) {
        handlePrintRequisitionClickProp(secondaryScreening);
      }
    } else {
      setSnackbar({ message: 'Failed to generate requisition', severity: 'error' });
    }
  };

  const handlePrintRequisitionClick = async () => {
    if (otherScreening) {
      setOption('confirm other screening requisition');
    } else {
      handlePrintRequisitionConfirm(screening);
    }
  };

  const handleRecommendedChanged = (newRecommended) => {
    setRecommended(newRecommended);
  };

  const { nextTest, statusDate, type } = screening;

  const cancerScreening = getCancerScreening(screening, patient);

  const preNewPatient = isPreNewPatient(screening);
  const screeningPreConsult = isScreeningPreConsult(screening);
  const screeningConsult = isScreeningConsult(screening);
  const nextTestQuestionnaire = isNextTestQuestionnaire(screening);
  const referral = isReferral(screening);

  const latestTest = getLatestTest(screening);
  const externalMrp = isScreeningExternalMrp(screening);
  const hasNewResult = hasLatestTestNotReviewed(screening);
  const hasNewHistory = hasChartHistoryNotReviewed(screening, patient);

  const riskStatus = preNewPatient ? null : screeningRiskStatus(screening, patient);

  // Chart updates
  const missingResult = isMissingResult(screening, patient);
  const referralReminderDue = isReferralReminderDue(screening, patient);

  // Does screening need test schedule set?
  //
  // Either:
  //  (a) new test result added or
  //  (b) need to set test schedule outside of adding new test result
  //
  //  Setting test due outside of new test result:
  //
  //    (1) No next test scheduled and need to start scheduling tests
  //        (new patient & return from referral)
  //
  //        Note: Next test not already scheduled - new patient, referral,
  //               aging out, very high risk and not applicable
  //
  //    (2) Patient consult providing medical questionnaire not for the purpose
  //        of consulting for being due for a test
  //        - scheduled medical questionnaire consult (e.g. cervical, lung)
  //        - update medical history outside of test consult window
  //
  //    (3) Consult in which patient updates questionnaire as part of test
  //        being due and becomes very high risk or not applicable
  //
  //    (4) Outside of patient consult, medical history edited by clinic which
  //        changes screening risk status and need next test date set or reason
  //        patient not tested reviewed
  //
  //    (5) Already accepted test schedule being edited by physician

  const setTestSchedule =
    hasNewResult ||
    hasNewHistory ||
    (!nextTest.due && recommended.isTestScheduled()) ||
    isNewPatient(screening) ||
    (!referral && recommended.referral()) ||
    (screeningConsult &&
      (nextTestQuestionnaire ||
        (nextTest.due && !inConsultWindow(nextTest.type, nextTest.due, screening)) ||
        recommended.text())) ||
    editingTestSchedule;

  return (
    <>
      <ExpansionPanel
        barColor={cancerColor(screening.type)}
        className={className}
        details={
          <div className={classes.gutter}>
            {!preNewPatient && (
              <>
                {/* Section - Risk status */}
                <div className={classes.section}>
                  <Typography className={classes.chartTitle}>Risk status</Typography>
                  <Typography>{riskStatus.risk}</Typography>
                  {riskStatus.indicators.map((item) => (
                    <div className={classes.item} key={item}>
                      <Typography variant="body2">{item}</Typography>
                    </div>
                  ))}
                </div>
                <Divider />
                {/* Section - Test schedule */}
                <div className={clsx(classes.section, classes.sectionTestSchedule)}>
                  <Typography className={classes.chartTitle}>Test schedule</Typography>
                  {editingTestSchedule && (
                    <Tooltip title="Discard edit">
                      <IconButton className={classes.closeButton} onClick={handleCancelEditingClick}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(cancerScreening.notApplicable && (
                    <>
                      {/* Not applicable indicated by physician */}
                      <div className={classes.item}>
                        <Typography>{recommended.text()}</Typography>
                      </div>
                    </>
                  )) ||
                    (setTestSchedule && (
                      <>
                        {
                          // Test schedule needs to be set
                          //
                          // Either:
                          //  (a) new test result added or
                          //  (b) set test schedule outside of adding new test result
                        }
                        {(hasNewResult && (
                          <>
                            {
                              // New test result added
                              //
                              // Physician reviews the test result and sets the
                              // test schedule. The patient is then notified of the
                              // new result and the new test schedule.
                            }
                            {/* eslint-disable no-shadow */}
                            <NewResultChart
                              handleAcceptClick={handleAcceptClick}
                              handleDeleteClick={handleDeleteTestClick(0, screening)}
                              handleEditClick={handleEditTestClick(latestTest, 0, screening)}
                              handleRecommendedChanged={handleRecommendedChanged}
                              patient={patient}
                              recommended={recommended}
                              screening={screening}
                              test={latestTest}
                              variant="chart"
                            />
                            {/* eslint-enable no-shadow */}
                          </>
                        )) || (
                          <>
                            {
                              //  Setting test schedule outside of new test result
                              //  (see: comment at start of file)
                            }
                            {/* eslint-disable no-shadow */}
                            <NextTestDue
                              editingTestSchedule={editingTestSchedule}
                              handleAcceptClick={handleAcceptClick}
                              handleRecommendedChanged={handleRecommendedChanged}
                              patient={patient}
                              recommended={recommended}
                              screening={screening}
                            />
                            {/* eslint-enable no-shadow */}
                          </>
                        )}
                      </>
                    )) ||
                    (referral && (
                      <>
                        {/* Patient already referred */}
                        <Typography>
                          {externalMrp
                            ? 'Patient referred to a specialist.'
                            : `Patient referred to specialist ${formatDateLongUiText(statusDate)}.`}
                          {!apiIsUserPhysician() &&
                            ((hasReferralBeenRequested(screening, patient) && (
                              <LabelBadge className={classes.badge} label="Refer to specialist" />
                            )) ||
                              (referralReminderDue && (
                                <LabelBadge className={classes.badge} label="Referral reminder" />
                              )))}
                        </Typography>
                        {(hasReferralBeenRequested(screening, patient) && (
                          <Typography
                            className={clsx(classes.notice, !apiIsUserPhysician() && classes.overdue)}
                            variant="body2"
                          >
                            {`Referral requested ${formatDateLongUiText(cancerScreening.referral.requested)}.`}
                          </Typography>
                        )) ||
                          (hasReferralBeenSent(screening, patient) && (
                            <Typography className={clsx(classes.notice)} variant="body2">
                              {`Referral sent ${formatDateLongUiText(cancerScreening.referral.sent)}.`}
                            </Typography>
                          ))}
                        {(referralReminderDue || hasReferralBeenRequested(screening, patient)) &&
                          !apiIsUserPhysician() && (
                            <div className={classes.actions}>
                              <Button
                                className={classes.button}
                                color="primary"
                                onClick={() =>
                                  referralReminderDue
                                    ? handleChartUpdateCompleteClick('referral reminder')
                                    : setOption('referral request')
                                }
                                variant="contained"
                              >
                                Record as done
                              </Button>
                            </div>
                          )}
                      </>
                    )) ||
                    (nextTest.due && (
                      <>
                        {
                          // Show test plan for existing patient with scheduled test.
                          //
                          // Chart actions
                          // (1) Patient consult then release requisition; or
                          // (2) Patient consult and preform referral if colonoscopy
                          //     has come due
                        }
                        <div className={clsx(classes.item, classes.itemAction)}>
                          <Typography>
                            {`${capitalize(testTypeName(nextTest.type, screening))}:`}
                            <span className={clsx(classes.spacing, missingResult && [classes.missing])}>
                              {formatDateLong(nextTest.due)}
                            </span>
                            {!apiIsUserPhysician() &&
                              ((screeningPreConsult && (
                                <LabelBadge className={classes.badge} label="Consult request" />
                              )) ||
                                (hasReferralBeenRequested(screening, patient) && (
                                  <LabelBadge
                                    className={classes.badge}
                                    label={`Refer for ${testTypeName(nextTest.type, screening)}`}
                                  />
                                )) ||
                                (missingResult && <LabelBadge className={classes.badge} label="Missing result" />) ||
                                (referralReminderDue && (
                                  <LabelBadge className={classes.badge} label="Referral reminder" />
                                )))}
                            {apiIsUserPhysician() &&
                              screeningConsult &&
                              ((testTypeReferPatient(nextTest.type, screening) && (
                                <>
                                  <LabelBadge className={classes.badge} label="Referral due" />
                                  <LabelBadge
                                    className={classes.badgeSecondary}
                                    label="Consult request"
                                    variant="secondary"
                                  />
                                </>
                              )) || (
                                <LabelBadge className={classes.badge} label="Consult request" variant="secondary" />
                              ))}
                          </Typography>
                          {apiIsUserPhysician() && (
                            <div className={classes.action}>
                              <Tooltip placement="top" title="Edit">
                                <IconButton onClick={handleEditTestScheduleClick}>
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        {(hasReferralBeenRequested(screening, patient) && (
                          <Typography
                            className={clsx(classes.notice, !apiIsUserPhysician() && classes.overdue)}
                            variant="body2"
                          >
                            {`Referral requested ${formatDateLongUiText(cancerScreening.referral.requested)}.`}
                          </Typography>
                        )) ||
                          (hasReferralBeenSent(screening, patient) && (
                            <Typography className={clsx(classes.notice)} variant="body2">
                              {`Referral sent ${formatDateLongUiText(cancerScreening.referral.sent)}.`}
                            </Typography>
                          ))}
                        {(referralReminderDue || hasReferralBeenRequested(screening, patient)) &&
                          !apiIsUserPhysician() && (
                            <div className={classes.actions}>
                              <Button
                                className={classes.button}
                                color="primary"
                                onClick={() =>
                                  referralReminderDue
                                    ? handleChartUpdateCompleteClick('referral reminder')
                                    : setOption('referral request')
                                }
                                variant="contained"
                              >
                                Record as done
                              </Button>
                            </div>
                          )}
                        {apiIsUserPhysician() && screeningConsult && (
                          <div className={classes.actions}>
                            {(testTypeReferPatient(nextTest.type, screening) && (
                              <Button
                                className={classes.button}
                                color="primary"
                                onClick={handleReferralClick(recommended, screening)}
                                variant="contained"
                              >
                                Request referral
                              </Button>
                            )) || (
                              <Button
                                className={classes.button}
                                color="primary"
                                onClick={() => handleRequistionReleasedComplete(screening)}
                                variant="contained"
                              >
                                Release requisition
                              </Button>
                            )}
                          </div>
                        )}
                      </>
                    )) || (
                      <>
                        {
                          // Display text recommendation not having a next test.
                          //
                          // Text recommendation when aged out, very high risk, or
                          // medical not applicable.
                        }
                        <div className={classes.item}>
                          <Typography>{recommended.text()}</Typography>
                        </div>
                      </>
                    )}
                  {externalMrp && (
                    <Typography className={classes.externalMrp} variant="body2">
                      Most responsible physician is not Chek Health.
                    </Typography>
                  )}
                </div>
                <Divider />
              </>
            )}
            {/* Section - Recent test results */}
            <div className={classes.section}>
              <Typography className={classes.chartTitle}>
                Recent tests
                {!apiIsUserPhysician() && preNewPatient && (
                  <LabelBadge
                    className={classes.badgeTitle}
                    label={screening.upgradeToScreening ? 'New screening' : 'New patient'}
                  />
                )}
              </Typography>
              {getReviewedTests(screening)
                .slice(0, 3)
                .map(({ test, index }) => (
                  <div className={clsx(classes.item, classes.itemAction, classes.listItem)} key={testKey(test)}>
                    <Typography className={classes.itemElement}>{formatDateLong(test.date)}</Typography>
                    <Typography className={classes.itemElementWide}>
                      {capitalize(testTypeName(test.type, screening))}
                    </Typography>
                    <TestValue screening={screening} test={test} />
                    <div className={classes.action}>
                      <Tooltip title="Edit">
                        <IconButton onClick={handleEditTestClick(test, index, screening)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={handleDeleteTestClick(index, screening)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {!apiIsUserPhysician() && preNewPatient && isLatestTest(test, screening) && externalMrp && (
                      <LabelBadge className={classes.badge} label="External MRP" variant="secondary" />
                    )}
                  </div>
                ))}
              <div className={clsx(classes.actions, classes.actionsAddTest)}>
                <Button
                  className={classes.buttonAddTest}
                  color="primary"
                  onClick={handleAddTestClick(screening)}
                  variant="outlined"
                >
                  <AddIcon className={classes.iconAdd} />
                  Add test result
                </Button>
              </div>
            </div>
            {/* Section - patient chart history sections:
                  - personal history
                  - social history
                  - family history
            */}
            {[
              {
                type: 'personalHistory',
                historyItems: chartPersonalHistoryToShow(screening, patient),
                addable: true,
              },
              {
                type: 'socialHistory',
                historyItems: chartSocialHistoryToShow(screening, patient),
                addable: false,
              },
              {
                type: 'familyHistory',
                historyItems: chartFamilyHistoryToShow(screening, patient),
                addable: true,
              },
            ].map(
              (chartHistory) =>
                chartHistory.historyItems !== null && (
                  <React.Fragment key={chartHistory.type}>
                    <Divider />
                    <div className={classes.section}>
                      <Typography className={clsx(classes.chartTitle, classes.center)}>
                        {capitalize(chartHistoryTypeName(chartHistory.type))}
                        {chartHistory.addable && (
                          <>
                            <Tooltip title="Add">
                              <IconButton
                                className={classes.addCircleButton}
                                size="small"
                                onClick={handleAddChartHistoryClick(chartHistory.type, screening)}
                              >
                                <AddCircleIcon className={classes.addCircleIcon} />
                              </IconButton>
                            </Tooltip>
                            {!apiIsUserPhysician() && preNewPatient && (
                              <LabelBadge
                                className={classes.badgeTitle}
                                label={screening.upgradeToScreening ? 'New screening' : 'New patient'}
                              />
                            )}
                          </>
                        )}
                      </Typography>
                      {chartHistory.historyItems.length === 0 ? (
                        <Typography className={clsx(classes.listItem, classes.emptyText)}>None</Typography>
                      ) : (
                        chartHistory.historyItems.map((item, index) => (
                          <div
                            className={clsx(
                              classes.item,
                              classes.listItem,
                              isChartHistoryItemUserEditable(item) && classes.itemAction,
                            )}
                            key={`${index.toString()}-${item.field}-${chartHistory.type}-${screening.type}`}
                          >
                            <Typography className={clsx(item.changed && classes.historyItem)}>
                              {(item.value && (
                                <>
                                  <span className={classes.dash}>-</span>
                                  {item.name}
                                </>
                              )) || (
                                <s>
                                  <span className={classes.dash}>-</span>
                                  {item.name}
                                </s>
                              )}
                            </Typography>
                            {/* Only remove items not already removed (e.g. value true).
                                Items with value false have already been removed and
                                shown as a 'change' */}
                            {isChartHistoryItemUserEditable(item) && (
                              <div className={classes.action}>
                                {item.onlyEdit ? (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={handleEditChartHistoryClick(item, screening)}>
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Remove">
                                    <IconButton onClick={handleRemoveChartHistoryClick(item, screening)}>
                                      <CancelIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
        }
        expanded={expanded}
        expandedSummary={
          <div className={classes.summary}>
            <Typography noWrap>{`${capitalize(type)} cancer`}</Typography>
            <div className={classes.summaryActions}>
              {!apiIsUserPhysician() && (preNewPatient || screeningPreConsult || missingResult) && (
                <DelayedFade in={expanded}>
                  <Button
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleChartUpdateCompleteClick(missingResult ? 'missing result' : null);
                    }}
                    variant="contained"
                  >
                    Chart update complete
                  </Button>
                </DelayedFade>
              )}
              {!apiIsUserPhysician() && requisitionAvailable(screening) && (
                <DelayedFade in={expanded}>
                  <Button
                    color="primary"
                    disabled={requisitionLoading}
                    onClick={(event) => {
                      event.stopPropagation();
                      handlePrintRequisitionClick();
                    }}
                    variant="outlined"
                    startIcon={requisitionLoading ? <MuiCircularProgress size={20} color="inherit" /> : null}
                  >
                    Print requisition
                  </Button>
                </DelayedFade>
              )}
            </div>
          </div>
        }
        handleClick={() => handleExpandClick()}
        handleExpandClick={() => handleExpandClick()}
        onTransitionEnd={onTransitionEnd}
        summary={
          <div className={classes.summary}>
            {(preNewPatient && (
              <>
                <div className={classes.flex}>
                  <Typography className={classes.summaryType} noWrap>
                    {`${capitalize(type)} cancer`}
                  </Typography>
                </div>
                {preNewPatient && !apiIsUserPhysician() && (
                  <div className={clsx(classes.summaryItem, classes.flexColumn)}>
                    <LabelBadge label={screening.upgradeToScreening ? 'New screening' : 'New patient'} />
                  </div>
                )}
              </>
            )) || (
              <>
                <div className={classes.flex}>
                  <Typography className={classes.summaryType} noWrap>
                    {`${capitalize(type)} cancer`}
                  </Typography>
                  <div className={classes.flexColumn}>
                    {(cancerScreening.notApplicable && (
                      <Typography noWrap>
                        <i>Not applicable</i>
                      </Typography>
                    )) ||
                      (hasNewResult && <TestValue screening={screening} test={latestTest} variant="double-line" />) ||
                      (externalMrp && (
                        <Typography noWrap>
                          <i>External MRP</i>
                        </Typography>
                      )) ||
                      (referral && (
                        <Typography noWrap>
                          <i>Patient referred</i>
                        </Typography>
                      )) ||
                      (nextTest.due && (
                        <Typography className={clsx(missingResult && [classes.missing])} component="span" noWrap>
                          {formatDateLong(nextTest.due)}
                        </Typography>
                      )) ||
                      (screening.notApplicable && (
                        <Typography noWrap>
                          <i>Not applicable</i>
                        </Typography>
                      )) ||
                      (screening.veryHighRisk && (
                        <Typography noWrap>
                          <i>Very high risk</i>
                        </Typography>
                      )) ||
                      (nextTest.agingOut && (
                        <Typography noWrap>
                          <i>Aged out</i>
                        </Typography>
                      ))}
                  </div>
                </div>
                <div className={clsx(classes.summaryItem, classes.flexColumn)}>
                  {!apiIsUserPhysician() &&
                    !editingTestSchedule &&
                    ((screeningPreConsult && <LabelBadge label="Consult request" />) ||
                      (hasReferralBeenRequested(screening, patient) && (
                        <LabelBadge
                          label={
                            nextTest.type
                              ? `Refer for ${testTypeName(nextTest.type, screening)}`
                              : 'Refer to specialist'
                          }
                        />
                      )) ||
                      (missingResult && <LabelBadge label="Missing result" />) ||
                      (referralReminderDue && <LabelBadge label="Referral reminder" />))}
                  {apiIsUserPhysician() &&
                    !editingTestSchedule &&
                    ((setTestSchedule && (
                      <LabelBadge
                        label={
                          (recommended.callPatient() && recommended.referPatient() && 'Call and refer') ||
                          (recommended.callPatient() && 'Call patient') ||
                          (recommended.referPatient() && 'Refer patient') ||
                          (recommended.isTestScheduled() && 'Set test date') ||
                          'Review'
                        }
                      />
                    )) ||
                      (screeningConsult && (
                        <LabelBadge
                          label={
                            (nextTest.due && testTypeReferPatient(nextTest.type, screening) && 'Refer patient') ||
                            (nextTest.due && 'Requisition request') ||
                            'Review'
                          }
                        />
                      )))}
                  {editingTestSchedule && (
                    <LabelBadge icon={<EditIcon fontSize="inherit" />} label="Editing" variant="primary" />
                  )}
                </div>
              </>
            )}
          </div>
        }
      />
      <DialogPaper
        open={['fast forward screening', 'fast forward referral'].includes(option)}
        onClose={handleClose}
        size="large"
      >
        <DialogTitle handleClose={handleClose} title="Ongoing screening task" />
        <DialogContent>
          {(option === 'fast forward referral'
            ? [
                'This is a new patient due for referral to a specialist.',
                'If the patient has already been referred outside of the Chek system, unselect the box below.',
                'Leave selected to foward to the Chek physician to make the referral.',
              ]
            : [
                `This is a new patient due for a ${testTypeName(recommended.nextTestType(), screening)}.`,
                'If the patient has already been referred outside of the Chek system, unselect the box below.',
                'Leave selected to foward to the Chek physician to make the referral.',
              ]
          ).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <DialogContentText key={index.toString()}>{item}</DialogContentText>
          ))}
          <FormControlLabel
            className={classes.dialogCheckbox}
            control={<Checkbox checked={!fastForward} color="primary" onChange={() => setFastForward(!fastForward)} />}
            label="Chek physician to make referral"
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={handleContinueClick}>
            Continue
          </Button>
        </DialogActions>
      </DialogPaper>
      <Dialog
        handleCancelClick={handleClose}
        handleOkClick={handleOkClick}
        okLabel="Yes, sent referral"
        open={option === 'referral request'}
        onClose={handleClose}
        title={`Record that the referral has been successfully sent to the ${
          isReferral(screening) ? 'speciality clinic?' : 'colonoscopy centre?'
        }`}
      />
      <Dialog
        cancelLabel={`${screening?.nextTest?.type?.toUpperCase()} only`}
        contentText={[
          `A ${otherScreening?.type} ${otherScreening?.nextTest?.type?.toUpperCase()}
            test requisition is also available.`,
          `Would you like the ${screening?.nextTest?.type?.toUpperCase()} and
            ${otherScreening?.nextTest?.type?.toUpperCase()} tests together in one requisition?`,
        ]}
        handleCancelClick={() => handlePrintRequisitionConfirm(screening)}
        handleOkClick={() => handlePrintRequisitionConfirm(screening, otherScreening)}
        okLabel="Both tests"
        onClose={handleClose}
        open={option === 'confirm other screening requisition'}
        title={`Include ${otherScreening?.nextTest?.type?.toUpperCase()} test?`}
      />
      <Snackbar
        open={!!snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(null)}
        message={snackbar?.message}
        ContentProps={{
          className: snackbar?.severity === 'error' ? classes.errorSnackbar : undefined,
        }}
      />
    </>
  );
}

ScreeningExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleAcceptClick: PropTypes.func.isRequired,
  handleAddChartHistoryClick: PropTypes.func.isRequired,
  handleAddTestClick: PropTypes.func.isRequired,
  handleChartUpdateCompleteClick: PropTypes.func.isRequired,
  handleDeleteTestClick: PropTypes.func.isRequired,
  handleEditChartHistoryClick: PropTypes.func.isRequired,
  handleEditTestClick: PropTypes.func.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handlePrintRequisitionClick: PropTypes.func.isRequired,
  handleReferralClick: PropTypes.func.isRequired,
  handleRemoveChartHistoryClick: PropTypes.func.isRequired,
  handleRequistionReleasedComplete: PropTypes.func.isRequired,
  onTransitionEnd: PropTypes.func.isRequired,
  otherScreening: PropTypes.object,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
};
ScreeningExpansionPanel.defaultProps = {
  otherScreening: null,
};

export default withStyles(styles)(ScreeningExpansionPanel);
