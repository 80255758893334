import { addDays, addYears, isBefore, startOfDay } from 'date-fns';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

function getTestReleaseType(testType) {
  switch (testType) {
    case 'chestCt':
    case 'fit':
    case 'mammogram':
    case 'mammogramAwbu':
    case 'psa':
      return 'requisition';

    case 'breastImaging':
    case 'breastBiopsy':
    case 'colposcopy':
    case 'hpv':
    case 'colonoscopy':
    case 'colonoscopyCt':
      return 'booking';

    case 'pap':
      return 'instructions';

    case 'dischargeLetter':
    case 'questionnaire':
      return 'other';

    default:
      return null;
  }
}

function getOtherRequisitionType(type) {
  // FIT and PSA tests can be included together on the same requisition
  // when both are available and requested by the user.
  // For an optional screening test (e.g. PSA), the harms and benefits of
  // screening need to be read and explicitly acknowledged. Do not bundle optional requisition.
  if (['colon', 'prostate'].includes(type)) {
    const otherType = type === 'colon' ? 'prostate' : 'colon';
    return otherType;
  }
  return null;
}

export function requisitionOtherTestAvailable(screening, patient) {
  const otherType = getOtherRequisitionType(screening.type);
  if (!otherType) return null;

  const otherScreening = patient.screenings.find((s) => s.type === otherType);
  if (!otherScreening) return null;

  const hasRequisition = getTestReleaseType(otherScreening.nextTest?.type) === 'requisition';
  const isOptional = otherScreening.nextTest.optional;

  if (hasRequisition && !isOptional) {
    return otherScreening;
  }
  return null;
}

// Note: This does not exactly match App code for requisition download, we are ignoring optionality as a factor.
export function requisitionAvailable(screening) {
  const { nextTest } = screening;
  const lastTest = screening?.tests?.[0];

  const released = nextTest?.requisitionReleased;
  const hasRequisition = getTestReleaseType(nextTest?.type) === 'requisition';
  const reflexiveBreast = nextTest?.type === 'breastBiopsy' || nextTest?.type === 'breastImaging';
  const externalFollowUpColonoscopy =
    nextTest?.type === 'colonoscopy' &&
    lastTest?.external === true &&
    lastTest?.date !== null &&
    !isBefore(startOfDay(addYears(lastTest?.date, 1)), startOfDay(nextTest?.due));
  const externalRepeatPSA =
    nextTest?.type === 'psa' &&
    lastTest?.external === true &&
    lastTest?.type === 'psa' &&
    lastTest?.date !== null &&
    !isBefore(startOfDay(addDays(lastTest?.date, 60)), startOfDay(nextTest?.due));
  const waitingForResults = reflexiveBreast || externalFollowUpColonoscopy || externalRepeatPSA;

  return released && hasRequisition && !waitingForResults;
}

export async function printRequisition(patientId, screeningType, extra) {
  try {
    const app = getApp();
    const functions = getFunctions(app, 'northamerica-northeast1');
    const clinicHandle = httpsCallable(functions, 'clinic');
    const args = { patientId, screeningType };
    if (extra) args.extra = extra;
    const response = await clinicHandle({ request: 'requisition', arguments: args });
    const options = { type: 'application/pdf' };
    const blob = new Blob([Uint8Array.from(atob(response.data), (c) => c.charCodeAt(0))], options);
    const pdfUrl = window.URL.createObjectURL(blob);
    const pdfTab = window.open(pdfUrl, '_blank');
    pdfTab.onload = () => {
      setTimeout(() => window.URL.revokeObjectURL(pdfUrl), 0);
    };
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
}
